import GalleryView from '../component/GalleryView/GalleryView';


const Gallery = () => {

    return (
        <GalleryView />
    );
}

export default Gallery