
const NotFound = () =>{
    
    return (
        <div style={{textAline: 'center'}}>
            <h2>Page not found !</h2>
        </div>
    );
};

export default NotFound;