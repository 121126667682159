import React from 'react'

const About = () => {
    return (
        <div>
            <h1>About page</h1>
            <p>Morbi at justo vitae arcu pellentesque sodales vel quis turpis. Ut ornare id ex et molestie. Etiam porttitor nunc a nisi vulputate, vel molestie nulla tincidunt. Aliquam erat volutpat. Cras leo justo, rhoncus id velit sed, tincidunt molestie sapien. Ut mollis vehicula finibus. Mauris interdum blandit vestibulum. Etiam orci ipsum, ullamcorper id fringilla a, pulvinar sollicitudin turpis. Sed non ornare sem. Vestibulum metus nulla, facilisis eget sem dictum, posuere ornare elit. Donec vulputate nisi lorem, vitae lacinia urna convallis in. Nunc sed metus tempus, ultrices ante et, convallis massa.</p>
            <p>Morbi at justo vitae arcu pellentesque sodales vel quis turpis. Ut ornare id ex et molestie. Etiam porttitor nunc a nisi vulputate, vel molestie nulla tincidunt. Aliquam erat volutpat. Cras leo justo, rhoncus id velit sed, tincidunt molestie sapien. Ut mollis vehicula finibus. Mauris interdum blandit vestibulum. Etiam orci ipsum, ullamcorper id fringilla a, pulvinar sollicitudin turpis. Sed non ornare sem. Vestibulum metus nulla, facilisis eget sem dictum, posuere ornare elit. Donec vulputate nisi lorem, vitae lacinia urna convallis in. Nunc sed metus tempus, ultrices ante et, convallis massa.</p>
            <p>Morbi at justo vitae arcu pellentesque sodales vel quis turpis. Ut ornare id ex et molestie. Etiam porttitor nunc a nisi vulputate, vel molestie nulla tincidunt. Aliquam erat volutpat. Cras leo justo, rhoncus id velit sed, tincidunt molestie sapien. Ut mollis vehicula finibus. Mauris interdum blandit vestibulum. Etiam orci ipsum, ullamcorper id fringilla a, pulvinar sollicitudin turpis. Sed non ornare sem. Vestibulum metus nulla, facilisis eget sem dictum, posuere ornare elit. Donec vulputate nisi lorem, vitae lacinia urna convallis in. Nunc sed metus tempus, ultrices ante et, convallis massa.</p>
            <p>Morbi at justo vitae arcu pellentesque sodales vel quis turpis. Ut ornare id ex et molestie. Etiam porttitor nunc a nisi vulputate, vel molestie nulla tincidunt. Aliquam erat volutpat. Cras leo justo, rhoncus id velit sed, tincidunt molestie sapien. Ut mollis vehicula finibus. Mauris interdum blandit vestibulum. Etiam orci ipsum, ullamcorper id fringilla a, pulvinar sollicitudin turpis. Sed non ornare sem. Vestibulum metus nulla, facilisis eget sem dictum, posuere ornare elit. Donec vulputate nisi lorem, vitae lacinia urna convallis in. Nunc sed metus tempus, ultrices ante et, convallis massa.</p>
        </div>
    )
}

export default About